import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy {
  protected subscriptions = new Subscription();
  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
